export default `<?xml version="1.0" encoding="utf-8"?>
<svg role="img" class="loader"  aria-labelledby="loading-aria" preserveAspectRatio="none" viewBox="0 0 185 124" height="150" width="100%" xmlns="http://www.w3.org/2000/svg">
  <title id="loading-aria">Loading...</title>
  <rect x="-199" width="1615" height="997" clip-path="url(#clip-path)" style="fill: url('#fill');"/>
  <defs>
    <clipPath id="clip-path">
      <rect x="1" y="20" width="183" height="71"/>
      <rect x="36" y="2" width="113" height="11"/>
      <rect y="101" width="183" height="21"/>
    </clipPath>
    <linearGradient id="fill">
      <stop offset="0.6" stop-color="#f3f3f3" stop-opacity="1">
        <animate attributeName="offset" values="-2; -2; 1" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"/>
      </stop>
      <stop offset="1" stop-color="#ecebeb" stop-opacity="1">
        <animate attributeName="offset" values="-1; -1; 2" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"/>
      </stop>
      <stop offset="1" stop-color="#f3f3f3" stop-opacity="1">
        <animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"/>
      </stop>
    </linearGradient>
  </defs>
</svg>`;
