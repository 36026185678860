import React from 'react';
import ReactDOM from 'react-dom/client';

export function render(target, component, props, loader = 'Loading..') {
  const el = React.createElement('svg', {});
  el.innerHTML = loader;

  ReactDOM.createRoot(target).render(
    React.createElement(
      React.Suspense,
      { fallback: el },
      React.createElement(component, {
        ...props,
        loadingElement: el
      })
    )
  );

  /**
   * This is a workaround to issue
   * where react rendered inside shadow dom
   * is not reactive to the first click event,
   * reasons are not known at this point.
   *
   * this solution simulates click after DOM
   * has changed in the rendered root (shadow dom)
   * after cx-module has been rendered
   *
   */

  new MutationObserver(function (e) {
    if (e[0].removedNodes) {
      const element = target.querySelector('div');
      if (element) {
        element.click();
      }
    }
  }).observe(target, { childList: true });
}
