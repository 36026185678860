import { html } from '../../utils/lit/html.js';
import { upgradeProperty } from '../../utils/upgradeProperty/upgradeProperty.js';
import {
  fetchCampaignData,
  cxModuleLoader,
  render
} from '../../services/cx-module-helper';
import { loadClient } from '@cx-modules/bet-builder/index.cjs.js';
import loader from './assets/loader';
import themes from './themes';
import { isAnalyticsDisabled } from '../../utils/isAnalyticsDisabled/isAnalyticsDisabled.js';
import { Analytics } from '../../services/analytics.js';

const WIDGET_VERSION = '1.0.1'; // NOTE: This value should match the last version from the widget's CHANGELOG.md file.
const WIDGET_NAME = 'oc-bet-builder-widget';

let clickoutServiceUrl = '';
let ocApiHost = '';

try {
  clickoutServiceUrl = process.env.CLICKOUT_SERVICE_URL;
  ocApiHost = process.env.OC_API_HOST || window.location.origin;
} catch {}

const wEvent = (name, detail) =>
  new CustomEvent(`${WIDGET_NAME}:${name}`, {
    bubbles: true,
    composed: true,
    detail
  });

const template = document.createElement('template');

template.innerHTML = html`
  <style>
    :host {
      box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
    }

    ${themes} #root {
      width: inherit;
      height: inherit;
    }
  </style>

  <div id="root"></div>
  <slot name="loading" hidden> ${loader} </slot>
  <slot name="error" hidden></slot>
`;

class OCBetBuilderWidget extends HTMLElement {
  constructor() {
    super();

    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    this._version = WIDGET_VERSION;
  }

  get slotId() {
    return this.getAttribute('slot-id');
  }

  set slotId(value) {
    this.setAttribute('slot-id', value);
  }

  get overrideUrl() {
    return this.getAttribute('override-url');
  }

  set overrideUrl(value) {
    this.setAttribute('override-url', value);
  }

  get subeventId() {
    return this.getAttribute('subevent-id');
  }

  set subeventId(value) {
    this.setAttribute('subevent-id', value);
  }

  get betIds() {
    return this.getAttribute('bet-ids');
  }

  async connectedCallback() {
    upgradeProperty(this, 'slotId');
    upgradeProperty(this, 'overrideUrl');
    upgradeProperty(this, 'subeventId');

    this.#loadCxComponent();
  }

  async #loadCxComponent() {
    const loadingSlot = this.shadowRoot.querySelector('slot[name="loading"]');
    const errorSlot = this.shadowRoot.querySelector('slot[name="error"]');
    let contextualBetIds;

    loadingSlot.hidden = false;

    if (this.betIds && typeof this.betIds === 'string') {
      contextualBetIds = this.betIds.split(',').map(id => parseInt(id));
    }

    try {
      const target = this.shadowRoot.getElementById('root');

      const [{ Component }, campaignData] = await Promise.all([
        cxModuleLoader(loadClient, this.shadowRoot),
        fetchCampaignData(this.slotId, this.overrideUrl, {
          ocSubeventId: this.subeventId,
          ocBetIds: contextualBetIds
        })
      ]);

      if (!Component || !target) {
        return;
      }

      const {
        apiKey,
        betIds,
        oddsFormat,
        affIds,
        eventId,
        subeventId,
        categoryGroupId,
        trackingRate,
        marketType,
        widgetStyle,
        gtmId,
        repub
      } = campaignData;

      const analytics = new Analytics(gtmId);

      if (!analytics.getDataLayer()) {
        analytics.integrateAnalytics();
      }

      const props = {
        affIds,
        dataApiEndpoint: ocApiHost,
        apiKey,
        clickoutServiceUrl,
        oddsFormat,
        repub,
        disableAnalytics: isAnalyticsDisabled(trackingRate),
        analyticsDataLayer: analytics.getDataLayer(),
        analyticsImpressionEventData: {
          event_type: marketType
        },
        analyticsImpressionEventName: 'bet_builder_impressions'
      };

      if (categoryGroupId) {
        props.categoryGroupId = parseInt(categoryGroupId);
      }

      if (eventId) {
        props.eventId = parseInt(eventId);
      }

      if (subeventId) {
        props.initialSubeventId = parseInt(subeventId);
      }

      if (widgetStyle) {
        props.theme = widgetStyle;
      }

      if (betIds) {
        props.initialBetIds = betIds;
      }

      props.analyticsImpressionEventData['widget_type'] =
        props.initialBetIds?.length > 0 ? 'pre_populated' : 'build_your_own';
      render(target, Component, props, loader);

      this.dispatchEvent(wEvent('load-success', campaignData));
    } catch (error) {
      errorSlot.hidden = false;

      this.dispatchEvent(wEvent('load-error', { error }));
    } finally {
      loadingSlot.hidden = true;

      this.dispatchEvent(wEvent('load'));
    }
  }
}

if (window.customElements && !window.customElements.get(WIDGET_NAME)) {
  window.customElements.define(WIDGET_NAME, OCBetBuilderWidget);
}
