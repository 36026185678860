import {
  fetchCampaignData,
  widgetBootstrap
} from '../../services/cx-module-helper';
import { loadClient } from '@cx-modules/bet-builder/index.cjs.js';
import loader from './assets/loader';
import themes from './themes';
import beGableAwareDarkUrl from '../../shared/assets/icons/be-gamble-aware-icon-black.svg';

let clickoutServiceUrl = '';
let ocApiHost = '';

try {
  clickoutServiceUrl = process.env.CLICKOUT_SERVICE_URL;
  ocApiHost = process.env.OC_API_HOST || window.location.origin;
} catch {}

const contract = ({
  affIds,
  apiKey,
  analytics,
  betIds,
  campaignName,
  categoryGroupId,
  eventId,
  geolocation,
  oddsFormat,
  marketType,
  repub,
  subeventId,
  widgetStyle,
  hash: widgetSlot
}) => {
  const props = {
    affIds,
    apiKey,
    dataApiEndpoint: ocApiHost,
    clickoutServiceUrl,
    oddsFormat,
    repub,
    disableAnalytics: !!analytics,
    analyticsImpressionEventData: {
      campaignName,
      eventType: marketType,
      widgetType: 'bet-builder',
      variation: betIds?.length > 0 ? 'pre_populated' : 'build_your_own',
      widgetSlot
    },
    analyticsImpressionEventName: 'bet_builder_impressions',
    geolocation
  };

  if (analytics) {
    props.analyticsDataLayer = analytics.getDataLayer();
  }

  if (categoryGroupId) {
    props.categoryGroupId = parseInt(categoryGroupId);
  }

  if (eventId) {
    props.eventId = parseInt(eventId);
  }

  if (subeventId) {
    props.initialSubeventId = parseInt(subeventId);
  }

  if (widgetStyle) {
    props.theme = widgetStyle;
  }

  if (betIds) {
    props.initialBetIds = betIds;
  }

  return props;
};

const fetchCampaign = attr => {
  let contextualBetIds;

  if (attr.betIds && typeof attr.betIds === 'string') {
    contextualBetIds = attr.betIds.split(',').map(id => parseInt(id));
  }

  return fetchCampaignData(attr.slotId, attr.overrideUrl, {
    ocSubeventId: attr.subeventId,
    ocBetIds: contextualBetIds
  });
};

const customCss = `:host {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
  }

  #root {
    width: inherit;
    height: inherit;
  }

  ${themes}`;

const disclaimer = ({ widgetStyle }) => {
  if (widgetStyle === 'reach')
    return {
      icons: `<img src="${beGableAwareDarkUrl}" alt="Over 18 Be Gamble Aware" />`,
      poweredby: true
    };
};

widgetBootstrap({
  attributes: ['subevent-id', 'bet-ids'],
  contract,
  customCss,
  cxClient: loadClient,
  disclaimer,
  fetchCampaign,
  loader,
  name: 'oc-bet-builder-widget',
  version: '1.0.1' // NOTE: This value should match the last version from the widget's CHANGELOG.md file.
});
